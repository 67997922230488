<template>
	<div style="height: 100%;">
		<template v-if="theme.is_mobile && theme.width < 750">
			<div class="head">
				<div>{{params.page}} / {{Math.ceil(params.total/params.perPage)}}，共 {{params.total}} 条记录</div>
				<div @click="popup_filter = true">筛选</div>
			</div>
			<div class="wrap">
				<div style="margin-bottom: 10px; padding: 10px; background-color: #fff;" v-for="article in articles" :key="article.id">
					<div>{{article.title}}</div>
					<div v-if="article.created_at">{{article.created_at}}</div>
				</div>
			</div>
			<van-pagination v-model="params.page" :total-items="params.total" :items-per-page="params.perPage" @change="(v) => { getContractsFees({ ...params, page: v }) }"></van-pagination>
		</template>
		<template v-else>
			<main-table>
				<el-form slot="header" :inline="true" :model="params" :size="theme.size">
					<el-form-item>
						<el-input v-model="params.keywords" placeholder="标题 or 关键词 or 描述" clearable></el-input>
					</el-form-item>
					<el-form-item>
						<el-select style="width: 100%;" placeholder="请选择标签" v-model="params.tags" :remote-method="remoteMethod" :loading="tags_loading" :multiple-limit="5" remote multiple filterable default-first-option>
							<el-option v-for="(tag, t) in tags" :key="t" :label="tag" :value="tag"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item  v-if="!admin_user.shop_id">
						<el-select v-model="params.shop_id" placeholder="门店" filterable clearable>
							<el-option v-for="shop in shops" :key="shop.id" :label="shop.name" :value="shop.id"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item>
						<el-cascader placeholder="请选择类目" v-model="params.categories" :options="categories" :props="{expandTrigger: 'hover', value: 'id', label: 'name', checkStrictly: true}" filterable clearable></el-cascader>
					</el-form-item>
					<el-form-item>
						<el-select slot="label" v-model="params.date_type" placeholder="请选择时间类型" filterable>
							<el-option label="创建时间" value="created"></el-option>
							<el-option label="更新时间" value="updated"></el-option>
						</el-select>
						<el-date-picker v-model="params.date" :picker-options="$config.DATE_PICKER" type="datetimerange" value-format="yyyy-MM-dd HH:mm:ss" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="getArticles({ ...params, page: 1})">查询</el-button>
						<el-button type="primary" @click="edit_content_drawer = true" :disabled="!$utils.create('articles')">创建文章</el-button>
					</el-form-item>
				</el-form>
				<el-table slot="table" class="scroll-wrapper absolute" height="100%" :data="articles" :size="theme.size" @sort-change="sortChange">
					<el-table-column fixed="left" type="selection" width="42"></el-table-column>
					<!-- <el-table-column label="封面图" width="100">
						<el-image slot-scope="scope" class="article_img" :src="scope.row.thumb" :preview-src-list="[scope.row.thumb]">
							<i slot="error" class="el-icon-picture-outline"></i>
						</el-image>
					</el-table-column> -->
					<el-table-column prop="id" label="名称/ID" sortable="custom">
						<template slot-scope="scope">
							<h3>{{scope.row.title}}</h3>
							<p>
								{{scope.row.id}}
								<span v-if="scope.row.category"> · {{scope.row.category.name}}</span>
								<span v-if="scope.row.author"> · {{scope.row.author}}</span>
								<span v-if="scope.row.user"> · {{scope.row.user.name}}<template v-if="scope.row.shop">@{{scope.row.shop.name}}</template></span>
							</p>
							<div>
								<el-checkbox :size="theme.size" @change="changeArticles({ action: 'publishing', id: scope.row.id})" :value="scope.row.published_at!=null" :disabled="!$utils.checkAuth('articles', 'publishing') || scope.row.deleted_at">发布</el-checkbox>
								<el-checkbox :size="theme.size" @change="changeArticles({ action: 'topping', id: scope.row.id})" :value="scope.row.topped_at!=null" :disabled="!$utils.checkAuth('articles', 'topping') || scope.row.deleted_at">置顶</el-checkbox>
							</div>
							<p style="margin-top: 2px;">
								<el-tag size="mini" style="margin-right: 10px;" v-for="tag in scope.row.tags" :key="tag.id">{{tag.name}}</el-tag>
							</p>					
						</template>
					</el-table-column>
					<el-table-column prop="views" label="SEO优化/浏览" sortable="custom">
						<template slot-scope="scope">
							<el-input v-model="scope.row.keywords" placeholder="请输入文章关键词" @change="submitArticles(scope.row)" :size="theme.size" style="margin-bottom: 5px" :readonly="!$utils.update('articles')" :disabled="scope.row.deleted_at" maxlength="48" show-word-limit>
								<template slot="prepend">{{scope.row.views}}</template>
							</el-input>
							<el-input type="textarea" rows="2" v-model="scope.row.description" placeholder="请输入文章描述" @change="submitArticles(scope.row)" :size="theme.size" :readonly="!$utils.update('articles')" :disabled="scope.row.deleted_at" maxlength="168" show-word-limit></el-input>
						</template>
					</el-table-column>
					<el-table-column prop="at" label="操作时间" width="210" v-if="theme.width >= 1080">
						<template slot-scope="scope">
							<template v-if="scope.row.deleted_at">删除：{{scope.row.deleted_at}}</template>
							<template v-else>更新：{{scope.row.updated_at}}<br>创建：{{scope.row.created_at}}</template>
						</template>
					</el-table-column>
					<el-table-column fixed="right" label="操作" width="140">
						<template slot-scope="scope">
							<el-button type="text" @click="onRestore(scope.row)" :disabled="!$utils.restore('articles')" v-if="scope.row.deleted_at">恢复</el-button>
							<template v-else>
								<el-tooltip placement="left">
									<vue-qr slot="content" :margin="10" :size="100" :text="'http://sm.dajing.ren/#/views/article/show?id='+scope.row.id"></vue-qr>
									<el-button type="text" @click="$utils.openWindow($config.ROOTPATH+'articles/'+scope.row.id)">浏览</el-button>
								</el-tooltip>
								<el-button type="text" @click="onEditor(scope.row)" :disabled="!$utils.update('articles')">编辑</el-button>
								<el-button type="text" @click="onDelete(scope.row)" :disabled="!$utils.delete('articles')">删除</el-button>
							</template>
						</template>
					</el-table-column>
				</el-table>
				<el-pagination
					slot="footer"
					:layout="$config.PAGE_LAYOUT"
					:page-sizes="$config.PAHE_SIZES"
					:page-size="params.perPage"
					:current-page="params.page"
					:total="params.total"
					@size-change="(v) => { getArticles({...params, page: 1, perPage: v}) }"
					@current-change="(v) => { getArticles({ ...params, page: v}) }"
					background>
				</el-pagination>
			</main-table>
			<!-- 编辑图文 -->
			<el-drawer ref="edit_drawer" direction="rtl" :withHeader="false" :visible.sync="edit_content_drawer" :size="theme.width <= 750?'100%':'750px'" :close-on-press-escape="false" :before-close="handleClose" @closed="article = {}; editor && editor.destroy();" destroy-on-close append-to-body>
				<Toolbar mode="simple" style="border-bottom: 1px solid #ccc" :editor="editor" :defaultConfig="toolbarConfig"></Toolbar>
				<div style="padding: 10px 10px 0;">
					<div style="display: flex;">
						<el-upload class="avatar-uploader" :action="$api.URI_UPLOADS" :accept="article_thumb_cfg.ext.map((e) => { return '.'+e; }).join(',')" :on-success="(r, f, l) => { !r.code ? (article = {...article, thumb: r.result.url}) : $message.error(r.msg)}" :data="{type: article_thumb_cfg.type}" :show-file-list="false" :before-upload="beforeThumbAttach">
							<img v-if="article.thumb" :src="article.thumb" class="avatar">
							<div v-else class="avatar-uploader-icon" style="font-size: 18px;">点击上传封面</div>
						</el-upload>
						<el-form ref="article_form" label-width="0px" style="flex: 1;" size="mini" :model="article">
							<el-form-item prop="title" :rules="[{ required: true, message: '请输入文章名称', trigger: 'blur' }]">
								<el-input placeholder="请输入文章标题" v-model="article.title" maxlength="38" show-word-limit clearable>
									<el-select slot="prepend" style="width: 120px;" v-if="!admin_user.shop_id" v-model="params.shop_id" placeholder="城市站点" filterable clearable>
										<el-option v-for="shop in shops" :key="shop.id" :label="shop.name" :value="shop.id"></el-option>
									</el-select>
								</el-input>
							</el-form-item>
							<el-form-item prop="categories" :rules="[{ required: true, message: '请选择类目', trigger: 'blur' }]">
								<el-cascader placeholder="请选择文章所属类目。支持输入搜索" style="width: 100%;" v-model="article.categories" :options="categories" :props="{expandTrigger: 'hover', value: 'id', label: 'name'}" filterable clearable></el-cascader>
							</el-form-item>
							<el-form-item prop="tags">
								<el-select style="width: 100%;" placeholder="请选择文章标签" v-model="article.tags" :remote-method="remoteMethod" :loading="tags_loading" :multiple-limit="5" remote multiple filterable allow-create default-first-option>
									<el-option v-for="(tag, t) in tags" :key="t" :label="tag" :value="tag"></el-option>
								</el-select>
							</el-form-item>
							<el-form-item>
								<div style="display: flex;">
									<el-input placeholder="请输入文章来源" v-model="article.write"></el-input>
									<el-input placeholder="请输入文章作者" v-model="article.author" style="margin-left: 10px;"></el-input>
								</div>
							</el-form-item>
						</el-form>
					</div>
					<div style="border-radius: 6px; overflow: hidden; border: 1px solid #ccc;" v-if="edit_content_drawer">
						<Editor mode="simple" :style="'height: '+(theme.height - 300)+'px; overflow-y: hidden;'" v-model="article.source" :defaultConfig="editorConfig" @onCreated="createdEditor"></Editor>
					</div>
				</div>
			</el-drawer>
		</template>
	</div>
</template>

<style src="@wangeditor/editor/dist/css/style.css"></style>
<style type="text/css">
	.mobile {
		display: flex; flex-direction: column; justify-content: space-between; height: 100%;
	}
	.mobile .head {
		display: flex; line-height: 45px; color: #666; font-size: 14px; border-bottom: 1px solid #ccc; padding: 0 10px; justify-content: space-between; background-color: #fff;
	}
	.mobile .wrap {
		flex: 1; background-color: #f5f5f5; overflow: auto;
	}
	.avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		margin-right: 10px;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}
	.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}
	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 240px;
		height: 180px;
		line-height: 180px;
		text-align: center;
	}
	.avatar {
		width: 240px;
		height: 180px;
		display: block;
	}
	.article_img {
		width: 80px;
		height: 80px;
		font-size: 40px;
		line-height: 80px;
		text-align: center;
		background-color: #f1f1f1;
	}
	.view-tag {
		color: #fff;
		font-size: 12px;
		padding: 0 10px;
		background-color: rgba(0, 0, 0, .6);
		position: absolute;
		right: 0;
		top: 0;
	}
</style>

<script>
	import { mapState } from 'vuex';
	import vueQr from 'vue-qr';
	import { Boot } from '@wangeditor/editor';
	import { Editor, Toolbar } from '@wangeditor/editor-for-vue';
	import mainTable from '@/components/main-table';

	class closeButtonMenu {
		constructor () {
			this.title = '关闭';
			this.tag = 'button';
		}
		getValue () {
			return false;
		}
		isActive () {
			return false;
		}
		isDisabled() {
			return false;
		}
		exec (editor) {
			if (this.isDisabled(editor)) return false;
			editor.emit('closeContent');
		}
	}

	class saveButtonMenu {
		constructor () {
			this.title = '保存';
			this.tag = 'button';
		}
		getValue () {
			return false;
		}
		isActive () {
			return false;
		}
		isDisabled() {
			return false;
		}
		exec (editor) {
			if (this.isDisabled(editor)) return false;
			editor.emit('saveContent');
		}
	}

	class clearHtmlButtonMenu {
		constructor () {
			this.title = '纯文';
			this.tag = 'button';
		}
		getValue () {
			return false;
		}
		isActive () {
			return false;
		}
		isDisabled (editor) {
			if (!editor.getText()) return true;
			return false;
		}
		exec (editor) {
			if (this.isDisabled(editor)) return false;
			const text = editor.getText().replace(/^\s*[\r\n]/gm, "");
			editor.clear();
			editor.insertText(text);
		}
	}

	class segmentHtmlButtonMenu {
		constructor () {
			this.title = '分段';
			this.tag = 'button';
		}
		getValue () {
			return false;
		}
		isActive () {
			return false;
		}
		isDisabled (editor) {
			if (!editor.getText()) return true;
			return false;
		}
		exec (editor) {
			if (this.isDisabled(editor)) return false;
			const text = editor.getText().replace(/^\s*[\r\n]/gm, "");
			editor.setHtml(text);
		}
	}

	export default {
		components: {
			vueQr,
			Editor,
			Toolbar,
			mainTable
		},
		computed: {
			...mapState(['theme', 'admin_user']),
			article_thumb_cfg () {
				return this.$utils.uploadConfig('article_thumb');
			},
			article_detail_cfg () {
				return this.$utils.uploadConfig('article_detail');
			},
			toolbarConfig () {
				return {
					insertKeys: {
						index: 0,
						keys: ['save_content', 'close_content', '|', {
							key: 'group-format-html',
							title: '格式',
							menuKeys: ["clear_html", "segment_html"]
						}]
					}
				};
			},
			editorConfig () {
				const { type, size, ext } = this.article_detail_cfg;
				return {
					placeholder: '请输入图文内容...',
					maxLength: 10000,
					MENU_CONF: {
						uploadImage: {
							allowedFileTypes: ext.map((e) => { return '.'+e; }),
							maxFileSize: size,
							fieldName: 'file',
							server: this.$api.URI_UPLOADS,
							meta: { type },
							onError: (file, err) => {
								this.$message.error('上传出错：'+err);
							},
							customInsert: (res, insertFn) => {
								const { code, msg, result } = res;
								if (code) return this.$message.error(msg);
								insertFn(result.url, result.name);
							}
						}
					}
				};
			}
		},
		methods: {
			createdEditor (editor) {
				editor.on('saveContent', () => {
					this.$refs['article_form'].validate(async (valid) => {
						if (!valid) return this.$message.error('表单提交有误！');
						this.submitArticles(this.article);
					});
				});
				editor.on('closeContent', () => {
					this.$refs['edit_drawer'].closeDrawer();
				});
				// 一定要用 Object.seal() ，否则会报错
				this.editor = Object.seal(editor);
			},
			handleClose (done) {
				this.$confirm('确定要关闭编辑吗?').then(() => { done(); });
			},
			sortChange (e) {
				let o = e.order ? (e.prop+':'+e.order) : null;
				let p = this.params;
					p.order = o;
				this.getArticles(p);
			},
			beforeThumbAttach (file) {
				const { size, format_size_unit } = this.article_thumb_cfg;
				const is = file.size < size;
				if (!is) return this.$message.error('上传封面图不能超过 '+format_size_unit);
				return is;
			},
			async remoteMethod (query) {
				if (query !== '') {
					this.tags_loading = true;
					const res = await this.$http.get(this.$api.URI_TAGS, { params: {action: 'search', word: query} });
					this.tags = res.data.result.map((tag) => { return tag.name; });
					this.tags_loading = false;
				} else {
					this.tags = [];
				}
			},
			async changeArticles (r) {
				const res = await this.$http.get(this.$api.URI_ARTICLES, {params: r, headers: {loading: true}});
				const { code, msg } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.$message.success(msg);
				this.getArticles(this.params);
			},
			async submitArticles (data) {
				const res = await this.$http.post(this.$api.URI_ARTICLES, data);
				const { code, msg } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.$message({
					type: 'success',
					duration: 1000,
					message: msg,
					onClose: () => {
						this.getArticles(this.params);
						this.edit_content_drawer = false;
					}
				});
			},
			onRestore (r) {
				this.$confirm('确定要恢复【'+r.title+'】文章吗？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					const res = await this.$http.get(this.$api.URI_ARTICLES, {params: {action: 'restore', id: r.id}, headers: {loading: true}});
					const { code, msg } = res.data;
					if (code != 0) return this.$message.error(msg);
					this.$message({
						type: 'success',
						duration: 1000,
						message: msg,
						onClose: () => {
							this.getArticles(this.params);
						}
					});
				})
			},
			onDelete (r) {
				this.$confirm('确定要删除【'+r.title+'】文章吗？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					const res = await this.$http.get(this.$api.URI_ARTICLES, {params: {action: 'delete', id: r.id}, headers: {loading: true}});
					const { code, msg } = res.data;
					if (code != 0) return this.$message.error(msg);
					this.$message({
						type: 'success',
						duration: 1000,
						message: msg,
						onClose: () => {
							this.getArticles(this.params);
						}
					});
				})
			},
			async onEditor (r) {
				const res = await this.$http.get(this.$api.URI_ARTICLES, {params: {action: 'update', id: r.id}, headers: {loading: true}});
				const { code, msg, result } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.article = { ...r, ...result.content, tags: r.tags.map((t) => { return t.name; }) };
				this.edit_content_drawer = true;
			},
			async getArticles (p, l = false) {
				const res = await this.$http.get(this.$api.URI_ARTICLES, {params: p, headers: {loading: l}});
				const { code, msg, result } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.articles = result.data;
				this.shops = result.shops;

				this.categories = this.$utils.buildTree(result.categories);
				this.params = {
					...p,
					total: result.total,
					perPage: parseInt(result.per_page)
				}
			}
		},
		data() {
			return {
				tags: [],
				shops: [],
				articles: [],
				categories: [],
				editor: null,
				tags_loading: false,
				edit_content_drawer: false,
				article: {},
				params: {
					perPage: 10,
				},
				rules: {
					name: [{ required: true, message: '请输入文章名称', trigger: 'blur' }],
					category_id: [{ required: true, message: '请输入文章分类', trigger: 'blur' }]
				}
			}
		},
		created () {
			this.getArticles(this.params, true);
			const module = {
				menus: [
					{
						key: 'save_content',
						factory() {
							return new saveButtonMenu();
						}
					}, {
						key: 'close_content',
						factory() {
							return new closeButtonMenu();
						}
					}, {
						key: 'clear_html',
						factory() {
							return new clearHtmlButtonMenu();
						}
					}, {
						key: 'segment_html',
						factory() {
							return new segmentHtmlButtonMenu();
						}
					}
				]
			}
			Boot.registerModule(module);
		}
	};
</script>